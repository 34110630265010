.section {
  padding: 60px 0;
  background: #edf8fe;
}
.section_top {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  position: relative;
  opacity: 1;
  background-image: url("../../../src/images/banner.png");
}
.section_top::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #27272773;
  opacity: 0.4;
}

.filters {
  border-bottom: 1px solid #e8e9eb;
  border-top: 1px solid #e8e9eb;
}

.filters {
  margin-bottom: 45px;
}

.fil_home {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .fil_home {
    flex-direction: column;
  }

  .section_top {
    height: 116px;
  }
}
