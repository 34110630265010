.cart_sec {
  padding: 60px 0;
  min-height: 300px;
}

.cart_b {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid#d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  margin-top: 65px;
}

.big_f {
  font-size: 46px;
  text-align: center;
  color: #383838;
}

.cart_sec > p {
  text-align: center;
  margin-top: 12px;
  font-size: 17px;
  letter-spacing: 1px;
  color: grey;
}

.cart_sec > div {
  text-align: center;
  margin-top: 12px;
}

.cart_space {
  padding: 0 40px;
}

.cart_p {
  font-weight: 700;
  color: #444444;
}

.side_total {
  margin: 60px 0 34px;
  text-align: right;
  text-align: right;
}
