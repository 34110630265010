.nav-link {
  color: #929292;
  padding: 0px 10px;
  font-weight: 600;
}

.basket {
  color: rgb(51 122 178);
  font-size: 30px !important;
}

.nav_link_top {
  text-align: center;
}

.top_nav {
  padding: 4px 0;
}

.title {
  color: rgb(51 122 178) !important;
  flex-grow: 1;
  font-size: 26px !important;
  margin-top: 14px !important;
  font-weight: 700 !important;
}

@media (max-width: 768px) {
  .nav_link_top {
    display: none;
  }

  .head_nav {
    max-width: 44px !important;
  }

  .title {
    font-size: 16px !important;
  }
}
