.card__title {
  padding: 8px 8px;
  padding: 8px 8px;

  /* border: 2px solid orange; */
  padding-left: 20px;
  color: #757575;
  border-radius: 4px;
}

.price-item {
  letter-spacing: 1px;
  font-weight: 700;
}

.product_image {
  max-height: 150px;
  min-height: 150px;
  width: 90%;
  border-radius: 0!important;
  margin: auto;}
/* }
.slick-slide{
  margin:0 10px;
} */
.product__card {
  width: 225px;
  min-height: 250px;
  border-radius: 10px;
  box-shadow: 2px 3px 5px grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
margin: 10px 0;
/* border: 2px solid orange; */

}


@media screen and (max-width: 600px) {
  .product__card {
    justify-content: center;
    width: 280px;

  }
}
@media screen and (max-width:450px) {

   .product__card {
    justify-content: center;
    width: 230px;

  }
}
@media screen and (max-width:360px){
  .product__card {
    width: 155px;
    margin:0 20px;

  }
}

