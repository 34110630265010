.hero-form {
    background-color: #fcfcfc;
    padding: 0px 2rem 20px;
    border-radius: 0.5rem;
 
}

.hero-form .pr {
    position: relative;
    display: flex;
}
/* .hero-form-parent{
  border: "2px solid orange";
} */

.hero-form .form-control {
    border-radius: 0.4rem;
    margin-bottom: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  
}

.formDe{
    text-align: center;
    margin-top: 12px;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    outline: none;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}

h3.hero-form-desc {
    text-align: center;
    font-size: 24px;
    margin: 2px 0px 20px;
}


.fff{    
    font-size: 25px!important;
    color: rgb(51 122 178)!important;
    font-weight: 700!important;
    margin-top: 9px!important;
}