.platform__cards {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid grey;
  padding: 10px 0;
  min-height: 147px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: white;
}
.platform__cards:hover {
  box-shadow: 3px 3px 5px rgb(209, 209, 209), -3px -3px 5px rgb(226, 226, 226);
}
