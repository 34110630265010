* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}
