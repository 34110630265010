.review__card {
  width: 99.2%;
  /* width: 100%; */
  min-height: 300px;
  /* border: 2px solid orange; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  background: white;
  box-shadow: 4px 4px 5px rgba(255, 255, 255, 0.3) inset,
    -4px -4px 5px rgba(0, 0, 0, 0.1) inset;
    /* box-shadow: 4px 4px 5px rgba(255, 255, 255, 0.3) inset,
    -4px -4px 5px rgba(0, 0, 0, 0.1) inset, 10px 40px 40px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  padding: 0;}
@media screen and (max-width: 450px) {
  .review__card {
    flex-direction: column;
    max-height: fit-content;
  }
  .ombre-externe {
    padding: 5px;
  }
  .ombre-interne {
    padding: 5px;
  }
  .review__card_left {
    border-right: none !important;
  }
}
.review__card_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-top: 10px;
}
.review__card_img img {
  width: 100%;
  height: 100%;
}
.review__card_left {
  text-align: center !important;
  border-right: 2px solid rgb(165, 164, 164);
  min-width: 200px;
}
.review__card_right {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.review_card_desc {
  padding: 5px 20px 5px 50px;
  font-size: 14px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  overflow: hidden;
}
.quotes_review_left {
  margin-left: auto;
  margin-right: 20px;
  height: 40px;
  width: 40px;
}
.quotes_review_right {
  margin-left: 20px;
  margin-right: auto;
  height: 40px;
  width: 40px;
}
.reviews_card_container > .slick-prev {
  /* display: none; */
}
@media screen and (max-width:602px){
  .slick-prev:before,
.slick-next:before{
    display: none;
  }
}